<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#3CB043" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center py-16>
      <v-flex xs12 sm10>
        <v-layout wrap justify-center>
          <v-flex xs12 text-left>
            <span style="font-family: poppinssemibold; font-size: 25px"
              >My wishlist</span
            >
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5 justify-center v-if="wishlist.length > 0">
          <v-flex xs12 py-3 v-for="(item, i) in wishlist" :key="i">
            <v-card>
              <v-layout wrap py-5>
                <v-flex xs3 sm3 md2 v-if="item.product">
                  <v-img
                    contain
                    height="160px"
                    width="100%"
                    style="border-radius: 20px"
                    :src="mediaUURL + item.product.photos[0]"
                  ></v-img>
                </v-flex>
                <v-flex xs9 sm9 md10 pl-2 align-self-center>
                  <v-layout wrap justify-start fill-height>
                    <v-flex xs12 text-left v-if="item.product.productname">
                      <span
                        style="
                          font-family: poppinssemibold;
                          font-size: 15px;
                          color: #191919;
                        "
                      >
                        {{ item.product.productname }}
                        <span v-if="item.item.size">
                          ({{ item.item.size }})
                        </span>
                        <!-- <span v-if="item.product.productname.length > 25">
                          ...
                        </span> -->
                      </span>
                    </v-flex>
                    <!-- <v-flex xs12 pt-3 text-left v-if="item.item">
                      <span
                        style="
                          color: #191919;
                          font-size: 13px;
                          font-family: poppinsmedium;
                        "
                        >Size
                        <span style="font-family: poppinsregular"
                          >: {{ item.item.size }}</span
                        >
                      </span>
                    </v-flex> -->
                    <v-layout wrap justify-start>
                      <v-flex xs12 sm4 md4 lg3>
                        <v-layout wrap justify-start>
                          <v-flex xs12 pt-1 text-left v-if="item.category">
                            <span
                              style="
                                color: #191919;
                                font-size: 13px;
                                font-family: poppinsmedium;
                              "
                              >Category
                              <span style="font-family: poppinsregular"
                                >: {{ item.category.name }}
                              </span>
                            </span>
                          </v-flex>
                          <v-flex
                            xs12
                            pt-1
                            text-left
                            v-if="item.product.sellerId"
                          >
                            <span
                              style="
                                color: #191919;
                                font-size: 13px;
                                font-family: poppinsmedium;
                              "
                              >{{ item.product.sellerId.shopName }}
                            </span>
                          </v-flex>
                          <v-layout wrap justify-start>
                            <v-flex xs5 sm6 md5 lg4 pt-2>
                              <v-card outlined>
                                <v-select
                                  dense
                                  v-model="quantity"
                                  :items="items"
                                  item-text="name"
                                  item-value="value"
                                  readonly
                                  color="grey"
                                  :hide-details="true"
                                  height="25px"
                                  class="no-border pl-4"
                                  variant="underlined"
                                >
                                </v-select>
                              </v-card>
                            </v-flex>
                            <v-flex xs3 sm3 pa-2 v-if="item.item.size">
                              <v-btn
                                small
                                light
                                depressed
                                outlined
                                height="30px"
                                :ripple="false"
                                class="sizebutton px-2"
                              >
                                <span style="font-family: poppinssemibold">{{
                                  item.item.size
                                }}</span>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 sm8 md4 align-self-center>
                        <v-layout wrap align-center justify-start>
                          <v-flex xs12 sm12 md12 text-center v-if="item.item">
                            <v-layout wrap justify-start>
                              <v-flex xs12 pb-5>
                                <span
                                  style="
                                    color: #30b868;
                                    font-family: RobotoMedium;
                                    font-size: 24px;
                                  "
                                  >₹ {{ item.item.offerPrice }}</span
                                >
                              </v-flex>
                              <v-flex xs7 sm6 md7 align-self-center>
                                <v-btn
                                  color="#E96125"
                                  dark
                                  depressed
                                  @click="addCart(item)"
                                  ><span
                                    style="
                                      font-family: opensansregular;
                                      text-transform: none;
                                      font-size: 13px;
                                    "
                                    >Move to cart</span
                                  ></v-btn
                                >
                              </v-flex>
                              <v-flex
                                xs5
                                sm4
                                md4
                                text-left
                                style="cursor: pointer"
                              >
                                <v-dialog
                                  v-model="item.dialogDelete"
                                  max-width="600px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      color="red"
                                      dark
                                      depressed
                                      v-on="on"
                                      v-bind="attrs"
                                      ><span
                                        style="
                                          font-family: opensansregular;
                                          text-transform: none;
                                          font-size: 13px;
                                        "
                                        >Remove</span
                                      ></v-btn
                                    >
                                  </template>
                                  <v-card>
                                    <v-card-title
                                      style="
                                        font-family: poppinsregular;
                                        font-size: 15px;
                                        color: #000;
                                      "
                                      >Are you sure you want to delete this
                                      product?</v-card-title
                                    >
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        small
                                        @click="item.dialogDelete = false"
                                        >Cancel</v-btn
                                      >
                                      <v-btn
                                        color="blue darken-1"
                                        text
                                        small
                                        @click="removeFromWishlist(item)"
                                        >OK</v-btn
                                      >
                                      <v-spacer></v-spacer>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-else>
          <v-flex xs12 sm12 align-self-center pt-5>
            <span
              style="
                font-family: poppinsregular;
                font-size: 25px;
                color: #000000;
                cursor: pointer;
              "
              >Oops! No Products Found</span
            >
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pt-2>
          <v-flex xs12>
            <div class="text-center pb-5" v-if="pages > 1">
              <v-pagination
                :length="pages"
                :total-visible="7"
                v-model="currentPage"
                color="#30B868"
                circle
              ></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 16,
      items: ["1", "2", "3", "4"],
      quantity: "1",
      wishlist: [],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  computed: {
    appLogin() {
      if (this.$store.state.isLoggedIn) return this.$store.state.isLoggedIn;
      else return null;
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        url: "/wishList",
        method: "GET",
        headers: headers1,
        params: {
          limit: this.limit,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.wishlist = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
          this.$store.commit("changeWishlist", response.data.wishlistcount);
        })
        .catch((err) => {
          this.appLoading = false;
          // this.ServerError = true;
          console.log(err);
        });
    },
    removeFromWishlist(item) {
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        method: "POST",
        url: "/wishList/remove",
        data: {
          id: item._id,
        },
        headers: headers1,
      })
        .then((response) => {
          if (response.data.status) {
            this.dialogDelete = false;
            this.getData();
            this.$store.commit("changeWishlist", response.data.wishlistcount);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addCart(item) {
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else {
        headers1 = {
          guestId: localStorage.getItem("userId"),
        };
      }
      axios({
        url: "/cart/add",
        method: "POST",
        data: {
          itemId: item.item._id,
          // guestid:this.guestid
          quantity: this.quantity,
        },
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.cart = response.data.data;
            this.removeFromWishlist(item);
            this.$store.commit("changeCart", response.data.totalLength);
            this.$router.push("/cart");
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
/* Add this style to remove the underline */
.theme--light.v-text-field.no-border
  > .v-input__control
  > .v-input__slot::before {
  border-width: 0;
}
</style>